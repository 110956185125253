import React from 'react'
import tw, { styled } from 'twin.macro'
import Layout from '../containers/Layout/Layout'
import Seo from '../components/Seo'
import { Link } from 'gatsby'
import PageCover from '../components/PageCover'
import BreadCrumbArrow from '../assets/svgs/breadcrumb-arrow.svg'
import PartnershipsCoverImage from '../assets/images/partnerships-bg.png'
import HandshakeImage from '../assets/images/handshake.png'
import BlueDotsImage from '../assets/images/blue-dots.png'
import AuxiliaryImage from '../assets/images/oil-machine.png'


import AbLogo from '../assets/images/ab-logo.png'
import AboLogo from '../assets/images/abo-logo.png'
import SchuckLogo from '../assets/images/schuck-group-logo.png'
import RikenKeikiLogo from '../assets/images/riken-keiki-logo.png'
import AumaLogo from '../assets/images/auma-logo.png'
import CathelcoLogo from '../assets/images/cathelco-logo.png'
import CosascoLogo from '../assets/images/cosasco-logo.png'

const PartnerBox = styled.div`
  ${tw`flex items-center justify-center w-5/6 mx-auto`}; 
  height: 112px;
  background: #FEFEFE;
  box-shadow: 0px 0.960068px 1.92014px 0.960068px rgba(0, 0, 0, 0.15);
  border-radius: 1.4401px;
  margin-bottom: 30px;
  
  @media only screen and (min-width: 640px) {
    width: 242px;
    margin: 0 25px 30px;
  }

  @media only screen and (min-width: 1280px) {
    margin-bottom: 67px;
  }
`

const PartnerImage = styled.img`
  
`

const PartnersFlexContainer = styled.div`
  ${tw`max-w-screen-xl mx-auto sm:flex px-5 justify-center items-center flex-wrap`}
  margin-bottom: 100px;

  @media only screen and (min-width: 1024px) {
    padding: 0 56px;
  }
`


const PartnershipsIntro = tw.section`max-w-screen-xl mx-auto px-5 py-20 sm:px-10 lg:px-14 xl:px-28 lg:py-24 flex items-start justify-between`;
const PartnershipsIntroContent = tw.section`md:w-6/12 lg:w-service-item-content`;

const PartnershipIntroContentHeading = styled.h2`
  ${tw`font-mulish font-extrabold text-brand-dark-200 mb-8 lg:mb-12 text-7/2 md:text-9/2 leading-tight tracking-brand`}
  margin-bottom: ${props => props.marginBottom};
`

const PartnershipsText = styled.p`
  ${tw`font-lato text-brand-dark-200 text-xl leading-brand-small`};
  margin-bottom: ${props => props.marginBottom}
`

const PartnershipsIntroVisuals = styled.section`
  ${tw`hidden md:flex items-start justify-end md:w-5/12 xl:w-service-item-visual h-service-item-visual relative`}
`
const PartnershipsIntroImageTag = tw.img``;

const PartnershipDotsImageTag = styled.img`
  ${tw`absolute`};
  z-index: 2;
  
  bottom: 81px;
  left: 140px;
`;

const PartnershipAuxiliaryImageTag = styled.img`
  ${tw`absolute`};
  z-index: 1;
  bottom: 71px;
  left: 10px;
`;

const PartnerBoxLink = styled.a`

  @media only screen and (min-width: 640px) {
    transition: all .4s;
  
    &:hover {
      transform: scale(1.5)
    }
  }
  
`



const Partnerships = () => {
  return (
    <Layout>
      <Seo title="Partnerships"/>

      <PageCover
        imageSrc={PartnershipsCoverImage}
        coverTitle="Partnerships"
      >
        <Link to="/">Home</Link>
        <div>
          <BreadCrumbArrow />
        </div>
        <Link to="/partnerships">Partnerships</Link>
      </PageCover>
      
      <PartnershipsIntro>
        <PartnershipsIntroContent>
          <PartnershipIntroContentHeading>
            Our Partners
          </PartnershipIntroContentHeading>
          <PartnershipsText>
            Over the years we have improved our service delivery in the oil and gas industry by leveraging on the foreign technical expertise of our partners.
          </PartnershipsText>
        </PartnershipsIntroContent>
        <PartnershipsIntroVisuals>
          <PartnershipsIntroImageTag src={HandshakeImage} alt="Handshake"/>
          <PartnershipDotsImageTag src={BlueDotsImage} alt="Blue Dots Background" />
          <PartnershipAuxiliaryImageTag src={AuxiliaryImage} />
        </PartnershipsIntroVisuals>
      </PartnershipsIntro>

      <PartnersFlexContainer>
        <PartnerBoxLink href="https://www.cosasco.com" target="_blank" rel="noreferrer">
          <PartnerBox>
            <PartnerImage src={CosascoLogo} width="204px" />
          </PartnerBox>
        </PartnerBoxLink>
        
        <PartnerBoxLink href="https://schuck-group.com" target="_blank" rel="noreferrer">
          <PartnerBox>
            <PartnerImage src={SchuckLogo} width="200px" />
          </PartnerBox>
        </PartnerBoxLink>
        
        <PartnerBoxLink href="https://www.auma.com" target="_blank" rel="noreferrer">
          <PartnerBox>
            <PartnerImage src={AumaLogo} width="155px" />
          </PartnerBox>
        </PartnerBoxLink>
        
        <PartnerBoxLink href="http://www.cathelco.com" target="_blank" rel="noreferrer">
          <PartnerBox>
            <PartnerImage src={CathelcoLogo} width="167px" />
          </PartnerBox>
        </PartnerBoxLink>
        
        <PartnerBoxLink href="https://www.rikenkeiki.co.jp/english" target="_blank" rel="noreferrer">
          <PartnerBox>
            <PartnerImage src={RikenKeikiLogo} width="143px" />
          </PartnerBox>
        </PartnerBoxLink>

        <PartnerBoxLink href="https://www.abovalve.com/en/" target="_blank" rel="noreferrer">
          <PartnerBox>
            <PartnerImage src={AboLogo} width="118px" />
          </PartnerBox>
        </PartnerBoxLink>
        
        <PartnerBoxLink href="https://www.abvalve.com/" target="_blank" rel="noreferrer">
          <PartnerBox>
            <PartnerImage src={AbLogo} width="87px"/>
          </PartnerBox>
        </PartnerBoxLink>
        
      </PartnersFlexContainer>
    </Layout>
  )
}

export default Partnerships